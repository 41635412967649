import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import privacy from './privacy.txt';

const Privacy = () => {
    const [fileContent, setFileContent] = useState('');
  
    useEffect(() => {
        fetch(privacy)
            .then((response) => response.text())
            .then((data) => {
                setFileContent(data);
            });
    }, []);
  
    return (
      <View>
        <Text style={styles.text}>{fileContent}</Text>
      </View>
    );
};

const styles = StyleSheet.create({
  text: {
    color: 'gray',
    padding: 50,
    fontSize: 18,
    fontFamily: 'Courier'
  },
});

export default Privacy;